// import Loadable from 'react-loadable';
import {lazy} from 'react'
// import Home from "../Home"
// import AboutUs from "../AboutUs"
// import News from "../News"
// import Detail from "../Detail"
// import Careers from "../Careers"

const routes = [{
  path: '/',
  exact: true
}, {
  path: '/index',
  component: lazy(() => import('../views/Layout')),
  routes:[
    {
      path: '/index/home',
      component: lazy(() => import('../views/Home')),
      exact: true
    },
    {
      path: '/index/aboutUs',
      component: lazy(() => import('../views/AboutUs')),
    },
    {
      path: '/index/productsServices',
      component: lazy(() => import('../views/AboutUs')),
    },
    {
      path: '/index/news',
      component: lazy(() => import('../views/News')),
    },
    {
      path: '/index/detail',
      component: lazy(() => import('../views/Detail')),
    },
    {
      path: '/index/careers',
      component: lazy(() => import('../views/Careers')),
    }
  ]
}, {
  path: '/login',
  component: lazy(() => import('../views/Layout')),
}];
export default routes;
