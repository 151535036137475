import React, { Suspense } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
const authPath = '/login'
const home = '/index/home'
const renderRoutes = (routes, authed) => {
  return routes ? (
    <Switch>
      {routes.map((route, i) => (
        <Route
          key={route.key || i}
          path={route.path}
          exact={route.exact}
          strict={route.strict}
          render={(props) => {
            if (route.path === '/') {
              return <Redirect to={{ pathname: home, state: { from: props.location } }} />;
            }
            if (!route.requiresAuth || authed || route.path === authPath) {
              const Component = route.component
              return (
                <Suspense fallback={<div>loading...</div>}>
                  <Component {...props} route={route}/>
                </Suspense>
              )
            }
            return <Redirect to={{ pathname: authPath, state: { from: props.location } }} />;
          }}
        />
      ))}
    </Switch>
  ) : null
};

export default renderRoutes;
