var home = require( './home')
var aboutUs = require( './aboutUs')
var footer = require( './footer')
var news = require( './news')
var detail = require( './detail')
var services = require( './services')
var careers = require( './careers')


module.exports = {
	english: {
		logo: './static/images/home/english-logo.png',
		menus: [{
			code: 'home',
			name: 'Home',
			children: []
		},
		{
			code: 'productsServices',
			name: 'Products&Services',
			children: []
		}, 
		{
			code: 'aboutUs',
			name: 'AboutUs',
			children: []
		}, {
			code: 'news',
			name: 'News',
			children: []
		},{
			code: 'careers',
			name: 'Careers',
			children: []
		},
		// {
		// 	code: 'qhse',
		// 	name: 'QHSE',
		// 	children: []
		// }
		],
		home: home.en,
		aboutUs: aboutUs.en,
		footer: footer.en,
		news: news.en,
		detail: detail.en,
		services: services.en,
		careers:careers.en
	},
	chinese: {
		logo: './static/images/home/english-logo.png',
		menus: [{
			code: 'Home',
			name: '首页',
			children: []
		}, {
			code: 'Products&Services',
			name: '产品&服务',
			children: []
		}, {
			code: 'AboutUs',
			name: '关于我们',
			children: []
		}, {
			code: 'Careers',
			name: '就职机会',
			children: []
		}, {
			code: 'QHSE',
			name: 'QHSE',
			children: []
		}],
		home:home.cn,
		aboutUs:aboutUs.cn,
		footer:footer.cn,
		news:news.cn,
		detail:detail.cn,
		services:services.cn,
		careers:careers.cn

	}
}