module.exports = {
	en: {
		banner: [
			{
				p1: 'we build only big things',
				p2:'Industrial Business',
				p3:'We Are Leader ',
				button:'Learn More',
				img: './static/images/home_slices/1.png',
			},
			{
				p1: 'Future of Manufacturing',

				img: './static/images/home_slices/2.png',
			},
			{
				p1: 'Smart Factory Solutions',
				img:'./static/images/home_slices/3.png',
			},						
			
		],
		news: {
			title:'./static/images/home_slices/new.png',
			left: [
				 {
					title: 'SLS was selected as one of the "Top 100 Singapore Private Enterprises in… Technological Innovation"',
					desc: 'Company news'
				},
				 {
					title: 'Oil Holds Steady Amid Small Rig Count Decline',
					desc: 'Industry news',
					img: './static/images/home_slices/new1.png',
				},
			     {
					title: 'Government adjusts oil profit margin',
					desc: 'Industry news',
					img: './static/images/home_slices/new2.png',
				},
			],
			mid:[
				{
					title: 'SLS Presents at ADIPEC 2019',
					img: './static/images/home_slices/new3.png',
				},
				 {
					title: 'Citibank upgrades SLS as a Global Strategic Partner',
					desc: 'Company news',
					
				},
			],
			right:[
				{
					title:'SLS and Bank of Kunlun signed 2.5 billion yuan strategic cooperation... agreement',
					desc:"Company news"
				},
				{
					data:[
						'Bloomberg：OPEC Signals Intention to Limit Supply...',
						//"Where Will Baker Hughes Be in 5 Years?",
						'OPEC sees lower 2020 demand for oil, points to surplus',
						'Oil Holds Steady Amid Small Rig Count Decline',
						'China Is Now Officially Open For Foreign Oil',
					
					]
				}
			]
		},
		news2:{
			bg: './static/images/home_slices/new4.png',
			tip:"About Us",
			title:"We're leading Industrial Solution in Worldwide",
			desc:"SLS is a representative Industrial Construction operator providing full range of service in the sphere of Civil Construction & Mechanical Engineering worldwide for any type of industrial soultions.			",

			data:[
				"Since our launch in 2020, high level projects were delivered.",
				'Highly Professional Staff, Accurate Testing Processes.',
				'Unrivalled workmanship.',
			]
		}
	},

	cn: {
		banner: [
			'./static/images/home_slices/1.png',
			'./static/images/home_slices/2.png',
			'./static/images/home_slices/3.png',
		],
		news: {
			left: [
				 {
					title: '青玉被评为“北京市科技创新百强民营企业”',
					desc: '公司新闻'
				},
				 {
					title: '石油持稳，钻机数量减少',
					desc: '行业新闻',
					img: './static/images/home_slices/new1.png',
				},
			     {
					title: '政府调整石油利润率',
					desc: '行业新闻',
					img: './static/images/home_slices/new2.png',
				},
			],
			mid:[
				{
					title: '青玉被评为“北京市科技创新百强民营企业”',
					desc: '公司新闻'
				},
				 {
					title: 'Oil Holds Steady Amid Small Rig Count Decline',
					desc: '行业新闻',
					img: './static/images/home_slices/new1.png',
				},
			],
			right:[
				{
					title:'青玉与昆仑银行签署25亿元战略合作。。。协议',
					desc:"公司新闻"
				},
				{
					title:'彭博社：欧佩克暗示有意限制供应。。。',
					data:[
						"贝克休斯5年后会在哪里？",
						'欧佩克预计2020年石油需求将下降，这意味着石油过剩',
						'石油持稳，钻机数量减少',
						'中国现在正式对外国石油开放'
					]
				}
			]
		},
		news2:{
			bg: './static/images/home_slices/new4.png',
			title:'青玉与昆仑银行签署25亿元战略合作。。。协议青玉与昆仑银行签署25亿元战略合作。。。协议',
			desc:"公司新闻",
			desc2:"公司新闻",		
			data:[
				"贝克休斯5年后会在哪里？",
				'欧佩克预计2020年石油需求将下降，这意味着石油过剩',
				'石油持稳，钻机数量减少',
				'中国现在正式对外国石油开放'
			]
		}
	}

}