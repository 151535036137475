
module.exports = {
    en: {
        bg: './static/images/home_slices/footerBg.png',
        list1: [
            {
                title: 'Navigation map',
                data: [
                    {
                        text: 'Products&Services',
                    },
                    {
                        text: 'About us',
                    },
                    {
                        text: 'News'
                    },
                    {
                        text: 'Careers'
                    },
                    {
                        text: 'QHSE'
                    }
                ]
            },
            {
                title: 'About Us',
                data: [
                    {
                        text: 'Corporate Profile',
                    },
                    {
                        text: 'Executive Management',
                    },
                    {
                        text: 'Corporate Culture'
                    },
                    {
                        text: 'Research'
                    },
                    {
                        text: 'History'
                    }
                ]
            },
            {
                title: 'Contact Us',
                data: [
                    {
                        text: 'Macedonia, the former Yugoslav Republic',
                        img:'./static/images/home_slices/icon0.png',
                    },
                    {
                        text: '008-9187667',
                        img:'./static/images/home_slices/icon1.png',
                    },
                    {
                        text: 'SLS@gmail.com',
                        img:'./static/images/home_slices/icon2.png',
                    },
                    {
                        text: 'SLS@gmail.com',
                        img:'./static/images/home_slices/icon3.png',
                    }
                ]
            }
        ],
        list2:{
            title:'Newsletter',
            desc:'Your email',
            button:'Subscribe',
            img:'./static/images/home_slices/icon5.png',
        },
        bottom:[
            'SLS International ©2020 All Right',
            'Privacy Policy',
            'Help',
            'Sitemap',
            'Contace Us'
        ]
    },

    cn: {

    }

}