let a = {
    desc:"Oil Holds Steady Amid Small Rig Count Decline",
    date:"2020-12-12"
}
let data = []
for(let i=0;i<1;i++){
    data.push(a)
}

module.exports = {
	en: {
		banner: [
			'./static/images/news/1.png',
		],
		data
	},

	cn: {
		
	}

}