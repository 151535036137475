import { makeAutoObservable} from 'mobx'
import appData from 'static/app'
class App {
    constructor() {
        makeAutoObservable(this)
    }
    language = 'english'
    appData = appData[this.language]
    currentMenu = 'Home'

    setLanguage(language) {
        this.language = language
        this.appData = appData[language]
    }
    setCurrentMenu(currentMenu) {
    //    console.log(currentMenu)
        this.currentMenu = currentMenu
        
      
    }
}
export default new App()