let a = 'The Chinese oil and gas sector is now officially open for foreign participation without a requirement to form joint ventures with local companies, but while Chinese media quote oil majors as praising the move, experts say that China needs additional regulatory reforms in order to attract foreign investment.  '
let data = []
for (let i = 0; i < 10; i++) {
  data.push(a)
}

let b = {
  title:'Citibank upgrades SLS as a Global…',
  date:'10-18 22:45',
  desc:' Industry News'
}
let data2 = []
for (let i = 0; i < 10; i++) {
  data2.push(b)
}
module.exports = {
  en: {
    right:{
      icon1: './static/images/detail/fire.png',
      icon2: './static/images/detail/hot.png',
      title:"Oil Spikes Despite Pandemic Uncertainty",
      banner: './static/images/detail/banner.png',
      data:data2
    },
    left: {
      title: 'China Is Now Officially Open For Foreign Oil, Gas Exploration',
      desc1: "11-21 12:32",
      icon1: './static/images/detail/clock.png',
      desc2: "#Industry News",
      banner: './static/images/detail/banner.png',
     
      otherNews: [{
        title: 'China Is Now Officially Open For Foreign Oil, Gas Exploration',
        date: "11-21 12:32",
      },
      {
        title: 'China Is Now Officially Open For Foreign Oil, Gas Exploration',
        date: "11-21 12:32",
      }

      ]
      , data,
    },
    
  },

  cn: {

  }

}