module.exports = {
	en: {
		banner: [
			'./static/images/careers/1.png',
			'./static/images/aboutUs/2.png',
			'./static/images/aboutUs/3.png',
		],
		data:[
		
			{
				title:'Description',
				desc:`1.Masters or outstanding undergraduates in major petroleum majors, geological research, mechanical design, safety engineering, IT, finance, finance, legal affairs, human resources, English translation, minor languages ​​(Arabic/French/Russian) and other related majors;
				<br>2. Strong learning ability, excellent grades during school, scholarships or other honors are preferred;
				<br>3.English level 6, English IELTS score above 6 or equivalent, good oral English, can be used as a working language for communication;
				<br>4.Excellent overall quality and can be sent abroad.
				`			
			},{
				title:'Job Requirements',
				desc:`1. Boundaryless development: provide global jobs, multi-departmental rotation, cross-cultural exchanges, rapid exercise and growth, and reserve for the management;
				<br>2. Leading teachers: Arrange internal experts as business mentors and the heads of the first-level departments as professional mentors after entering the job to quickly complete the identity change;
				<br>3. All-round training: personal development plan and training plan will be tailored immediately after entering the job to protect your career path;`	},
		{
			title:'Salary And Welfare',
			desc:`1. Salary structure: basic monthly salary + post subsidy + bonus + welfare;
			<br>2. Basic monthly salary: 10-12K;
			<br>3. The overseas assignment allowance is rich, and the comprehensive annual salary can be as high as 30-40W RMB;
			<br>4. If you have the chance to settle Beijing hukou, you can settle Tianjin hukou.`},
		]
	},

	cn: {
		
	}

}