module.exports = {
	en: {
		banner: [
			'./static/images/services/1.png',
			'./static/images/services/2.png',
			'./static/images/services/3.png',
		],
		data:[
			
			{
				title:`Drilling services`,
				desc:`SLS is one of the companies providing drilling services in Singapore. Not only can it provide users with a variety of single drilling technical services, but also can provide general engineering services, including conventional technology and high-end technology, and is well-known in the industry for providing high-quality high-end technical services.`,
				
			},
			{
				title:`Completion services`,
				desc:`SLS is one of the companies in Singapore that provides professional completion solutions services. The service content covers a wide range and can provide diversified well completion services.`,
				
			},
			{
				title:`Workover and fishing services`,
				desc:`SLS is one of Singapore's companies that carry out workover and salvage engineering technical services. The scope of services includes workover/fishing overall plan design demonstration, complex salvage, casing damage well management, accident prevention, open hole section technical services, efficient tool development, etc. A professional service organization with service capabilities.`,
				
			},
			{
				title:`Energy saving and environmental protection services`,
				desc:`Energy saving and consumption reduction, increasing reserves and production, dedicating energy, creating harmony, and green development are our development objectives.`,
				
			},
			{
				title:`Reservoir services`,
				desc:`Taking oilfield efficiency and economic development as the core goal, based on oilfield geology and oil production technology research, through system service methods such as difficult problem diagnosis, technical plan optimization, and comprehensive evaluation of oil and gas production data, we provide customers with comprehensive technical support and services. Help customers increase oil and gas production and maximize oil recovery.`,
				
			},
			{
				title:`Oil and gas field production, operation and maintenance services`,
				desc:`The guarantee of safe and stable production of oil and gas fields is the production, operation and maintenance of oil and gas fields. The group has established a high-quality, high-standard oilfield production, operation and maintenance service team, with a variety of operating qualifications, and can provide customers with integrated oilfield production, operation and maintenance services. It has completed and continues to undertake the operation and maintenance services of multiple oilfield operation areas, processing stations, refineries and other units.`,
				
			},
			{
				title:`Smart oilfield services`,
				desc:`Smart oilfields are based on the realization of informatization and interconnection of digital oilfields, and realize intelligent information management. It is an integral part of modern oilfields and will become an inevitable trend of sustainable development of oilfields. At present, our company has cooperated with well-known artificial intelligence service companies in Singapore to jointly promote the development of smart oil fields in Singapore.`,
				
			},
			]
	},

	cn: {
		
	}

}