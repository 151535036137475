module.exports = {
	en: {
		banner: [
			'./static/images/aboutUs/1.png',
			'./static/images/aboutUs/2.png',
			'./static/images/aboutUs/3.png',
		],
		data:[
			{
				
				desc:`SINGAPORE LOONG SAPPHIRE ENERGY COMPANY (hereinafter as "SLS") is the world's leading integrated oilfield technology service company, providing customers with a full range of products and services for oil and gas development, with a wide range of business coverage. The company has a high-quality management team, solid professional capabilities in petroleum and investment, rich corporate management experience and efficient execution.
<br/>nIn order to cater to the diverse needs of customers, SLS provides a full range of products in the oil and gas development process, and constantly improves its service quality, so that customers can maximize the value of oil and gas assets. SLS 's comprehensive products and services include: products and services throughout the entire process of oil and gas development, a full range of professional resource services for oil and gas development, and various forms of comprehensive services.
<br/>The vision of SLS is to become a model of harmonious and efficient development between people and the environment. SLS is committed to achieving the "operation first" standard and creating value for customers to the greatest extent. In the development process, SLS does its utmost to promote social progress and achieve harmonious development with society. SLS is based on the society, and it will return to the society. Our mission is to continuously export energy and create wealth for the society. The company will actively devote itself to social welfare undertakings and fulfill its due social responsibilities.`
			},
			{
				title:'Corporate Culture',
				desc:`The core values ​​of SLS are "Integrity, Innovation, Environmental Protection, Dedication".The company adheres to the concept of honesty, makes a promise, and does what it says. Company employees insist on fulfilling their trust and commitment to the company, customers and investors, and fulfill their job responsibilities. The company insists on operating in accordance with the law and abides by the ethical concept of integrity.
<br/>The company adheres to the concept of innovation and explores the unknown road with an innovative heart. Break the routine, be brave to try, and continuously optimize systems, processes, methods, tools, and keep pace with the times.
<br/>The company adheres to the concept of environmental protection, adheres to the scientific outlook on development, and establishes a scientific outlook on resources. The company is committed to protecting the ecological environment, saving energy and reducing emissions, developing a circular economy, minimizing the impact of oil exploration activities on the environment, and striving to create harmony between energy and the environment.
<br/>The company adheres to the concept of honesty, makes a promise, and does what it says. Company employees insist on fulfilling their trust and commitment to the company, customers and investors, and fulfill their job responsibilities. The company insists on operating in accordance with the law and abides by the ethical concept of integrity.
<br/>The company adheres to the concept of dedication. The employees of the company uphold the work attitude of being serious, conscientious, and striving for perfection, maintain a high work enthusiasm and a pragmatic and hard-working spirit, regard the dedication and contribution to the society as the supreme honor, and consciously resist the corrupt thoughts. Corrosion, guide and regulate professional behavior with correct outlook on life and values.`,
				
			},{
				title:'Reaserch',
				desc:`<span style='font-weight:900'>Technological research and development: </span>
<br/>Founded in 2015, SLS is an oil company with core competitiveness and continuous rapid development. Since its establishment, the group has been adhering to the concept of technology first and vigorously promoting the research and development of high-tech oilfield services. The technical services involved in the SLS Technology Center include drilling services, completion services, workover and fishing services,  reservoir services, oil and gas field production operation and maintenance services, energy saving and environmental protection services, and smart oilfield services. There are several R&D institutions under the Reservoir Geology Research Institute, Information Technology Center, Engineering Technology R&D Center, Post-Doctoral Workstation, and External Co-construction Laboratory, which are capable of rapidly forming independent products and proprietary technologies. SLS pays special attention to technological innovation and creates value for customers. SLS's annual R&D investment is always in the forefront of oil companies. We will continue to focus on research and development work to further strengthen our position as a leader in the Singapore petroleum industry.`
		},
		{
			title:'Global Supply Chain',
			desc:`<span style='font-weight:900'>Supply Chain Service</span>
			<br/>SLS advocates the concept of multi-party cooperation and platform-based development, which perfectly combines the supply chain with the ecological chain and the value chain, forming a characteristic supply chain that supports the rapid development of SLS Group's globalization strategy and responds quickly. After many years of operation, SLS has rich experience in cross-cultural management, cross-border operation management and strong global integration of resources. The oil supply chain service area covers many countries and regions, including Singapore, China, the Middle East, Africa, Central Asia, Southeast Asia , Latin America, with strong sourcing, procurement, logistics, warehousing and supplier management capabilities, creating a supply chain system that efficiently supports the world.
			`},
		{
			title:"Global Suppliers Management",
			desc:`With the intensification of economic competition in the international market, we are adhering to the concept of long-term cooperation and mutual support with suppliers to create a new partnership. There are currently 1,500 suppliers in the library, of which overseas suppliers account for 20%。`,
	}]
	},

	cn: {
		
	}

}