import { HashRouter as Router } from 'react-router-dom';
import renderRoutes from './routes/renderRoutes';
import routes from './routes';
import './app.less';
const App = () => {
  return (
    <Router>
        {renderRoutes(routes, false)}
    </Router>
  );
}

export default App;
